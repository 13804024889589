@use '../global/index' as g;

.clients {
  .clients__image {
    width: 100%;

    & > img {
      width: 100%;
    }
  }

  & > h2 {
    @include g.media-desktop {
      text-align: right;
    }
  }
}
