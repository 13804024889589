@mixin style-visually-hidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin media-header {
  @media screen and (min-width: 1366px) {
    @content;
  }
}

@mixin media-desktop {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin media-tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}
