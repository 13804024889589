@use '../global/index' as g;

.header {
  height: 8.5rem;
  padding: 4rem 4rem 2.5rem 4rem;
  width: 100%;
  position: fixed;
  z-index: 100;
  transition: transform 200ms ease-out, background-color 200ms ease-out;

  @include g.media-header {
    display: flex;
    justify-content: space-between;
  }

  &.hide {
    transform: translate3d(0, -100%, 0);
  }

  &.with-background {
    background-color: g.$col-black;
  }

  .header__social-list {
    display: flex;
  }

  .header__social-item + .header__social-item {
    margin-left: 1.6rem;

    @include g.media-header {
      margin-left: 2.1rem;
    }
  }

  .header__nav {
    background-color: g.$col-black;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    transform: translateX(100%);
    transition: transform 400ms ease-out;

    &--open {
      transform: translateX(0);
    }

    @include g.media-header {
      height: unset !important;
      width: unset;
      transform: translateX(0);
      position: unset;
      transition: unset;
      background-color: transparent;
    }
  }

  .header__nav-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include g.media-header {
      flex-direction: row;
    }
  }

  .header__nav-item {
    @include g.montserrat-18;
    letter-spacing: 0.2rem;
    text-transform: uppercase;

    & + .header__nav-item {
      margin-top: 8rem;
    }

    @include g.media-header {
      & + .header__nav-item {
        margin-top: 0;
        margin-left: 4rem;
      }
    }
  }

  .header__button {
    position: absolute;
    top: 4rem;
    right: 4rem;
    z-index: 10;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;

    & > svg {
      width: 2rem;
      height: 2rem;
      fill: g.$col-white;
    }

    & > .header__menu-icon {
      display: block;
    }

    & > .header__close-icon {
      display: none;
    }

    &--close {
      & > .header__menu-icon {
        display: none;
      }

      & > .header__close-icon {
        display: block;
      }
    }

    @include g.media-header {
      display: none;
    }
  }
}
