@use '../global/index' as g;

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .contact__social-list {
    display: flex;

    & > li + li {
      margin-left: 1.6rem;
    }

    & > li > a {
      display: block;
      margin-top: 2.4rem;
    }
  }

  & > div {
    display: flex;
    justify-content: center;
  }

  & > h2 {
    @include g.media-desktop {
      text-align: right;
      margin-bottom: 1.6rem !important;
    }
  }

  @include g.media-desktop {
    align-items: flex-end;
  }
}
