@use '../global/index' as g;

.dividers {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem 0;

  &--no-margin {
    margin: 0;
  }

  &--top {
    margin-top: 0;
  }

  &--end {
    @include g.media-desktop {
      margin-top: 0;
      margin-bottom: 4rem;
    }
  }

  &--team {
    display: none;

    @include g.media-desktop {
      display: block;
      grid-column: 2 / 3 !important;
      grid-row: span 6 !important;
      padding: 0 !important;
    }
  }

  &--bios {
    display: flex;

    @include g.media-desktop {
      display: none;
    }
  }

  .dividers__block {
    width: 0.7rem;
    height: 17rem;
    background-color: g.$col-white;

    &--small {
      height: 5.4rem;
    }

    &--medium {
      height: 8rem;
    }

    &--large {
      height: 5.4rem;
    }

    @include g.media-desktop {
      width: 1.2rem;
    }
  }

  &__angle {
    display: none;
    width: 12.8rem;
    height: 5rem;
    border-top: solid 12px white;
    margin-bottom: 4rem;

    @include g.media-desktop {
      display: block;
    }

    &--right {
      border-right: solid 12px white;
      grid-column: 3/4;
    }

    &--left {
      border-left: solid 12px white;
      grid-column: 1/2;
      justify-self: flex-end;
    }
  }
}
