@use '../global/index' as g;

.social-icon {
  fill: g.$col-white;
  width: 2rem;
  height: 2rem;

  &--twitter {
    width: 2.5rem;
    height: 2.5rem;
  }

  @include g.media-desktop {
    width: 3rem;
    height: 3rem;

    &--twitter {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
}
