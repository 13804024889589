@use '../global/index' as g;

.team {
  .team__image {
    margin-bottom: 3rem;
    position: relative;
    overflow: hidden;

    @include g.media-desktop {
      margin-bottom: 4rem;
    }

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: (9 / 16) * 100%;
    }

    & > img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;
      width: 100%;
    }
  }

  & p {
    margin-bottom: 2rem;

    @include g.media-desktop {
      margin-bottom: 4rem;
    }
  }

  & > div + div:not(.dividers) {
    margin-top: 3rem;
  }

  @include g.media-desktop {
    display: grid;
    grid-template-columns: 1fr 12px 1fr;
    padding: 0;

    & > h2 {
      grid-column: 3 / 4;
      text-align: left;
      padding: 0 6rem 0 11.6rem;
    }

    & > div:nth-of-type(even) {
      grid-column: 3 / 4;
      padding: 0 6rem 0 11.6rem;
    }

    & > div:nth-of-type(odd) {
      grid-column: 1 / 2;
      padding: 0 11.6rem 0 6rem;
    }

    & > div:nth-child(5) {
      grid-row: 4;
    }
  }
}
