@use '../global/index' as g;

.footer {
  width: 100%;
  background-color: g.$col-grey;
  padding: 4rem 3.6rem 6rem 3.6rem;
  text-transform: uppercase;

  & > a {
    text-align: center;
    display: block;
    margin-bottom: 2.4rem;
    @include g.montserrat-16;
    letter-spacing: 0.2rem;

    @include g.media-desktop {
      margin: 0;
      margin-right: 6.8rem;
    }
  }

  & > p {
    text-align: center;
    @include g.montserrat-16;
    letter-spacing: 0.2rem;
    font-weight: 900 !important;
  }

  @include g.media-desktop {
    display: flex;
    justify-content: center;
    padding: 11.2rem 4rem 16rem 4rem;
    font-size: 2.5rem;
  }
}
