@use './variables' as v;
@use './type' as t;
@use './mixins' as m;

*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  font-size: 62.5%;
  // height: 100%;
  overflow-x: hidden;
  background-color: v.$col-black;
  color: v.$col-white;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include t.montserrat-14;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

strong {
  font-style: italic;
}

h1 {
  @include m.style-visually-hidden;
}
