@use './mixins' as m;

@mixin montserrat-18 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 900;

  @include m.media-desktop {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

@mixin montserrat-16 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 900;

  @include m.media-desktop {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

@mixin montserrat-14 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: 400;

  @include m.media-desktop {
    font-size: 1.6rem;
    line-height: 3rem;
  }
}
