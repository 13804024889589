@use '../global/index' as g;

.grid {
  @include g.media-desktop {
    display: grid;
    grid-template-columns: 1fr 12px 1fr;

    & .dividers {
      grid-column: 2 / 3;
      grid-row: span 3;
      margin: 0;

      & > div {
        height: 100%;
      }
    }

    &--team {
      display: block;
      width: 100%;
    }

    .grid__item {
      &--left {
        grid-column: 1 / 2;
        padding: 0 11.6rem 0 6rem;
      }

      &--right {
        grid-column: 3 / 4;
        padding: 0 6rem 0 11.6rem;
      }
    }
  }
}
