@use '../global/index' as g;

.splash {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .splash__wordmark {
    width: 100%;
    max-width: 23.1rem;
    margin: -0.1rem auto 2rem auto;

    & > img {
      display: block;
      width: 100%;
    }
  }

  @include g.media-desktop {
    .splash__wordmark {
      margin: -0.1rem auto 4rem auto;
      max-width: 28vw;
    }
  }

  .splash__divider {
    flex: 1 0;

    & > * {
      height: 100%;
    }
  }
}
