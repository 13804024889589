@use '../global/index' as g;

.section {
  padding: 0 3.6rem;

  h2 {
    @include g.montserrat-18;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2rem;
    letter-spacing: 0.2rem;

    @include g.media-desktop {
      margin-bottom: 4rem;
    }
  }

  h3 {
    @include g.montserrat-16;
    width: 100%;
    margin-bottom: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;

    @include g.media-desktop {
      margin-bottom: 4rem;
    }
  }
}
