@use '../global/index' as g;

.top {
  display: flex;
  justify-content: center;
  margin-bottom: 14rem;

  .top__button {
    @include g.montserrat-16;
    letter-spacing: 0.2rem;
    background: none;
    border: none;
    text-transform: uppercase;
    color: g.$col-white;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}
